import PropTypes from "prop-types";
import Product from "@brand/Product/default/Product";
import { productShape } from "app/utils/propTypes";
import "./ProductList.scss";
import { memo } from "react";

const ProductList = ({ products = [], showSmartDPOnRootUrl }) => (
	<div className="product-list" data-testid="product-list">
		{products.map((product, index) => {
			return (
				<div
					key={product.id || (product.merchandising && product.merchandising.code)}
					className="product-list__item"
					data-testid={`product-${product.uri ||
						(product.merchandising && product.merchandising.code)}`}
				>
					<Product
						product={product}
						position={index}
						showSmartDPOnRootUrl={showSmartDPOnRootUrl}
					/>
				</div>
			);
		})}
	</div>
);

ProductList.propTypes = {
	products: PropTypes.arrayOf(productShape),
	showSmartDPOnRootUrl: PropTypes.bool,
};

export default memo(ProductList);
