import PropTypes from "prop-types";
import Paragraphs from "app/pages/.shared/Paragraphs";
import { SDP_HERO_TEXT_ALIGN } from "app/constants";
import "./SmartDPMarketingHeadline.scss";
import { memo } from "react";

const SmartDPMarketingHeadline = ({ title, subtitle, textAlign }) => {
	let align = "center";
	if (textAlign === SDP_HERO_TEXT_ALIGN.LEFT) {
		align = "flex-start";
	} else if (textAlign === SDP_HERO_TEXT_ALIGN.RIGHT) {
		align = "flex-end";
	}

	return (
		<div
			className="smartdp-marketing-headline"
			style={{
				alignItems: align,
			}}
		>
			<div className="smartdp-marketing-headline__title">
				<Paragraphs paragraphs={title} enableHTMLFormatting={true} />
			</div>
			<div className="smartdp-marketing-headline__subtitle">
				<Paragraphs paragraphs={subtitle} enableHTMLFormatting={true} />
			</div>
		</div>
	);
};

SmartDPMarketingHeadline.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	textAlign: PropTypes.string,
};

export default memo(SmartDPMarketingHeadline);
