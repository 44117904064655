import { memo } from "react";
import PropTypes from "prop-types";
import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";

const IMG_SIZES = {
	medium: { width: 978 },
	small: { width: 768 },
	large: { width: 2880 },
	xlarge: { width: 2880 },
};

const SmartDPMarketingHeroImage = ({ imageThumbnail, imageUrl, gradient }) => {
	return (
		<BackgroundProgressiveImg
			sizes={IMG_SIZES}
			backgroundPosition={"center"}
			thumbnail={imageThumbnail}
			src={imageUrl}
			quality="q_auto:best"
			gradient={gradient}
		/>
	);
};

SmartDPMarketingHeroImage.propTypes = {
	imageThumbnail: PropTypes.string,
	imageUrl: PropTypes.string,
	gradient: PropTypes.string,
};

export default memo(SmartDPMarketingHeroImage);
