import { memo, useCallback, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./SmartDPSearch.scss";
import { sendTagOnSDPSearchPage } from "app/utils/analytics";
import { DynamicBlocksRendererContainer } from "app/pages/.shared/DynamicBlocks";
import { PushNotificationContainer } from "app/pages/.shared/PushNotification/PushNotificationContainer";
import HeadMetadata from "app/utils/metas/HeadMetadata";
import SmartDPMarketingHeroImageContainer from "app/pages/SmartDP/SmartDPMarketingHeroImage/SmartDPMarketingHeroImageContainer";
import SmartDPMarketingHeadlineContainer from "app/pages/SmartDP/SmartDPMarketingHeadline/SmartDPMarketingHeadlineContainer";
import SmartDPSearchFormContainer from "app/pages/SmartDP/Search/SDPSearchForm/SmartDPSearchFormContainer";
import { buildSDPSearchPayload } from "app/utils/smartDPUtils";
import { useNavigate } from "react-router-dom";
import HomeSmartDPSearchInput from "app/pages/SmartDP/Search/SDPSearchForm/HomeSmartDPSearchInput";
import { useFsFlag } from "@flagship.io/react-sdk";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import HeaderContainer from "app/pages/Header/HeaderContainer";
import classNames from "classnames";

const SmartDPSearch = ({
	homeBlocks = [],
	resetAllSDPProductsFilter,
	smartDPRef = {},
	isBrandWithCustomHeader,
}) => {
	const navigate = useNavigate();
	const showMiniSDPFormFlag = useFsFlag("sdp_show_mini_form_mobile", false);
	const showMiniSDPForm = showMiniSDPFormFlag.getValue();

	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const smartDPSearchRef = useRef(null);

	const smartDPFormClass = classNames("smartdp-search__form", {
		"smartdp-search__form--mini-version": showMiniSDPForm && isMobile,
	});
	useEffect(() => {
		sendTagOnSDPSearchPage();
	}, []);

	const handleFormSubmission = useCallback(data => {
		const queryParams = buildSDPSearchPayload(data);

		resetAllSDPProductsFilter();

		navigate({ pathname: "/sdp/listing", search: queryParams });
	}, []);

	const themeFlag = useFsFlag("header_orange_use_custom", undefined);
	const useHeaderOrange = themeFlag.getValue();

	const containerClass = classNames({
		"smartdp-search__container": true,
		"smartdp-search__container--custom-header": isBrandWithCustomHeader || useHeaderOrange,
	});

	return (
		<div ref={smartDPSearchRef} className="smartdp-search">
			<HeadMetadata page="sdp-search" />
			<HeaderContainer />
			<div className={containerClass}>
				<div className="smartdp-search__image">
					<SmartDPMarketingHeroImageContainer
						gradient={
							isMobile
								? "linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #fff 100%)"
								: "linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, #fff 100%)"
						}
					/>
				</div>
				<div className="smartdp-search__content">
					<SmartDPMarketingHeadlineContainer />
					<div className={smartDPFormClass}>
						{showMiniSDPForm && isMobile ? (
							<HomeSmartDPSearchInput
								smartDPRef={smartDPRef}
								onSuccess={handleFormSubmission}
								showMiniSDPForm
							/>
						) : (
							<SmartDPSearchFormContainer onSuccess={handleFormSubmission} />
						)}
					</div>
					<div className="smartdp-search__dynamic-blocks">
						<DynamicBlocksRendererContainer blocks={homeBlocks} />
					</div>
				</div>
			</div>
			<PushNotificationContainer />
		</div>
	);
};

SmartDPSearch.propTypes = {
	homeBlocks: PropTypes.array,
	resetAllSDPProductsFilter: PropTypes.func,
	smartDPRef: PropTypes.object,
	isBrandWithCustomHeader: PropTypes.bool,
};

export default memo(SmartDPSearch);
