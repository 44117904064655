import { bindActionCreators } from "redux";
import { resetAllSDPProductsFilter } from "app/pages/SmartDP/Listing/SmartDPFilters/smartDPProductsFilterActionCreators";
import { connect } from "react-redux";
import SmartDPSearch from "app/pages/SmartDP/Search/SmartDPSearch";
import { hasCustomDynamicHeader } from "app/reducers/brandSelector";

const SmartDPSearchContainer = props => {
	return <SmartDPSearch {...props} />;
};

const mapStateToProps = state => {
	return {
		isBrandWithCustomHeader: hasCustomDynamicHeader(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			resetAllSDPProductsFilter,
		},
		dispatch
	);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SmartDPSearchContainer);
